import React from "react" 
import "./styles.scss"


const Button = ({ children, to, onClick, style, hollow }) => {
  const isLink = to !== undefined && to !== null && to !== ""

  return isLink ? (
    <a href={to}  className={hollow ? `hollow-button` : `button`} style={style}>
      {children}
    </a>
  ) : (
    <button className={hollow ? `hollow-button` : `button`} style={style} onClick={onClick}>
      {children}
    </button>
  )
}

export default Button 