import React from "react"
import "./styles.scss"

const ServiceBlock = ({title, description, animation}) => {

  const uniqueId = title.toLowerCase()
    .replace("&", "")
    .replace(/ /g, "-")
    

  return (
    <div id={uniqueId} className="service-block" key={title}>
      <div className="animation">{animation}</div>
      <h6>{title}</h6>
      <p>{description}</p>
    </div>
  )
}

export default ServiceBlock