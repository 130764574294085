import React from "react" 
import { Link as GatsbyLink } from "gatsby"
import "./styles.scss"

const Link  = (props) => {
  const classProps = { className: "underline-link" } 
  const combinedProps = {...props, ...classProps}

  return  <GatsbyLink {...combinedProps}>
    {props.children}
  </GatsbyLink>
}

export default Link

