import React from "react" 
import { ReactComponent as ScrollSvg } from "src/assets/svg/scroll-arrow.svg"
import "./styles.scss"

const ScrollIcon = () => {
  return <div className="scroll-icon">
    <ScrollSvg />
  </div>
}

export default ScrollIcon 