import * as React from "react";

function SvgCloudcomputing(props) {
  return (
    <svg
      id="cloudcomputing_svg__Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 146.25 198.29"
      {...props}
    >
      <defs>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient"
          x1={6.74}
          y1={73.76}
          x2={111.94}
          y2={52.49}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff8789" />
          <stop offset={0.44} stopColor="#84a2fc" />
          <stop offset={0.72} stopColor="#205fe3" />
          <stop offset={1} stopColor="#030f28" />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-2"
          x1={73.43}
          y1={16.94}
          x2={73.37}
          y2={68.13}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff8789" />
          <stop offset={0.53} stopColor="#84a2fc" />
          <stop offset={0.72} stopColor="#205fe3" />
          <stop offset={1} stopColor="#030f28" />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-3"
          x1={10.52}
          y1={76.88}
          x2={109.75}
          y2={56.85}
          xlinkHref="#cloudcomputing_svg__linear-gradient"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-4"
          x1={73.43}
          y1={23.25}
          x2={73.37}
          y2={71.59}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-5"
          x1={58.08}
          y1={22.68}
          x2={81.85}
          y2={77.32}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff8789" />
          <stop offset={0.53} stopColor="#84a2fc" />
          <stop offset={0.72} stopColor="#205fe3" />
          <stop offset={1} stopColor="#030f28" stopOpacity={0.88} />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-6"
          x1={88.09}
          y1={21.99}
          x2={71.9}
          y2={134.63}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff8789" />
          <stop offset={0.53} stopColor="#84a2fc" />
          <stop offset={0.72} stopColor="#205fe3" />
          <stop offset={1} stopColor="#030f28" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-7"
          x1={85.59}
          y1={31.93}
          x2={72.27}
          y2={124.07}
          xlinkHref="#cloudcomputing_svg__linear-gradient-6"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-8"
          x1={77.75}
          y1={53.27}
          x2={74.31}
          y2={53.4}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ef8dff" />
          <stop offset={1} stopColor="#ff8789" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-9"
          x1={15.77}
          y1={79.4}
          x2={12.35}
          y2={79.66}
          xlinkHref="#cloudcomputing_svg__linear-gradient-8"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-10"
          x1={132.71}
          y1={72.96}
          x2={136.43}
          y2={90.04}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#0ff" stopOpacity={0.47} />
          <stop offset={1} stopColor="#0ff" stopOpacity={0} />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-11"
          x1={73.39}
          y1={63.05}
          x2={74.19}
          y2={110.72}
          xlinkHref="#cloudcomputing_svg__linear-gradient-5"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-12"
          x1={60.58}
          y1={83.02}
          x2={73.55}
          y2={110.34}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ff8789" />
          <stop offset={0.39} stopColor="#84a2fc" />
          <stop offset={0.61} stopColor="#205fe3" />
          <stop offset={1} stopColor="#030f28" stopOpacity={0.88} />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-13"
          x1={120.18}
          y1={157.44}
          x2={24.59}
          y2={120.58}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-14"
          x1={121.08}
          y1={157.89}
          x2={25.49}
          y2={121.03}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-15"
          x1={122.03}
          y1={158.39}
          x2={26.44}
          y2={121.52}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-16"
          x1={122.98}
          y1={158.85}
          x2={27.39}
          y2={121.98}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-17"
          x1={123.88}
          y1={159.31}
          x2={28.33}
          y2={122.46}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-18"
          x1={124.79}
          y1={159.79}
          x2={29.2}
          y2={122.92}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-19"
          x1={125.74}
          y1={160.28}
          x2={30.15}
          y2={123.42}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-20"
          x1={126.65}
          y1={160.78}
          x2={31.06}
          y2={123.91}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-21"
          x1={127.59}
          y1={161.21}
          x2={32.04}
          y2={124.36}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-22"
          x1={128.5}
          y1={161.72}
          x2={32.91}
          y2={124.86}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-23"
          x1={129.44}
          y1={162.16}
          x2={33.87}
          y2={125.29}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-24"
          x1={130.35}
          y1={162.67}
          x2={34.77}
          y2={125.81}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-25"
          x1={131.3}
          y1={163.1}
          x2={35.75}
          y2={126.26}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-26"
          x1={132.21}
          y1={163.62}
          x2={36.62}
          y2={126.75}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-27"
          x1={133.16}
          y1={164.07}
          x2={37.57}
          y2={127.21}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-28"
          x1={134.1}
          y1={164.56}
          x2={38.51}
          y2={127.7}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-29"
          x1={135}
          y1={165.01}
          x2={39.46}
          y2={128.16}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-30"
          x1={135.96}
          y1={165.51}
          x2={40.37}
          y2={128.65}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-31"
          x1={41.38}
          y1={79.54}
          x2={95.2}
          y2={199.54}
          gradientTransform="matrix(1 0 0 -1 0 212.59)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#ef8dff" />
          <stop offset={1} stopColor="#ff8789" />
        </linearGradient>
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-32"
          x1={42.28}
          y1={80}
          x2={96.11}
          y2={200}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-33"
          x1={43.23}
          y1={80.49}
          x2={97.05}
          y2={200.49}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-34"
          x1={44.18}
          y1={80.95}
          x2={98}
          y2={200.94}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-35"
          x1={45.12}
          y1={81.45}
          x2={98.91}
          y2={201.39}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-36"
          x1={45.99}
          y1={81.89}
          x2={99.81}
          y2={201.89}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-37"
          x1={46.94}
          y1={82.38}
          x2={100.76}
          y2={202.38}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-38"
          x1={47.85}
          y1={82.88}
          x2={101.67}
          y2={202.88}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-39"
          x1={48.82}
          y1={83.34}
          x2={102.63}
          y2={203.29}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-40"
          x1={49.7}
          y1={83.83}
          x2={103.52}
          y2={203.82}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-41"
          x1={50.67}
          y1={84.28}
          x2={104.45}
          y2={204.24}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-42"
          x1={51.55}
          y1={84.77}
          x2={105.38}
          y2={204.77}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-43"
          x1={52.53}
          y1={85.23}
          x2={106.34}
          y2={205.19}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-44"
          x1={53.41}
          y1={85.72}
          x2={107.23}
          y2={205.72}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-45"
          x1={54.36}
          y1={86.17}
          x2={108.18}
          y2={206.17}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-46"
          x1={55.3}
          y1={86.67}
          x2={109.13}
          y2={206.67}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-47"
          x1={56.24}
          y1={87.13}
          x2={110.04}
          y2={207.08}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-48"
          x1={57.16}
          y1={87.61}
          x2={110.98}
          y2={207.61}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-49"
          x1={99.6}
          y1={196.67}
          x2={60.49}
          y2={124.85}
          xlinkHref="#cloudcomputing_svg__linear-gradient-2"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-50"
          x1={41.2}
          y1={79.58}
          x2={95}
          y2={199.58}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <linearGradient
          id="cloudcomputing_svg__linear-gradient-51"
          x1={57.24}
          y1={87.86}
          x2={110.99}
          y2={207.82}
          xlinkHref="#cloudcomputing_svg__linear-gradient-31"
        />
        <clipPath id="cloudcomputing_svg__clip-path">
          <path fill="none" d="M19.21 22.29h109.64v92.31H19.21z" />
        </clipPath>
        <style>{".cloudcomputing_svg__cls-7{opacity:.6}"}</style>
      </defs>
      <g id="cloudcomputing_svg__platform">
        <g id="cloudcomputing_svg__platform-2" data-name="platform">
          <path
            d="M124.38 149.05c3.06 1.81 3.06 4.69.05 6.5l-45.34 26.59a12 12 0 01-11.09 0l-45.6-26.59c-3.06-1.81-3.12-4.69 0-6.5l45.34-26.59a12 12 0 0111.08 0z"
            fill="url(#cloudcomputing_svg__linear-gradient)"
          />
          <path
            d="M124.38 149.05c3.06 1.81 3.06 4.69.05 6.5l-45.34 26.59a12 12 0 01-11.09 0l-45.6-26.59c-3.06-1.81-3.12-4.69 0-6.5l45.34-26.59a12 12 0 0111.08 0z"
            stroke="url(#cloudcomputing_svg__linear-gradient-2)"
            strokeWidth={0.73}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M121.48 145.33c2.88 1.71 2.88 4.43 0 6.14l-42.72 25.12a11.28 11.28 0 01-10.45 0l-43-25.12c-2.89-1.71-2.94-4.43-.05-6.14L68 120.22a11.25 11.25 0 0110.46 0z"
            fill="url(#cloudcomputing_svg__linear-gradient-3)"
          />
          <path
            d="M121.48 145.33c2.88 1.71 2.88 4.43 0 6.14l-42.72 25.12a11.28 11.28 0 01-10.45 0l-43-25.12c-2.89-1.71-2.94-4.43-.05-6.14L68 120.22a11.25 11.25 0 0110.46 0z"
            stroke="url(#cloudcomputing_svg__linear-gradient-4)"
            strokeWidth={0.73}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M131.9 149.55c3.47 2 3.47 5.29.06 7.33l-51.44 30a13.67 13.67 0 01-12.57 0l-51.74-30c-3.47-2-3.53-5.28-.06-7.33l51.44-30a13.67 13.67 0 0112.57 0z"
            stroke="url(#cloudcomputing_svg__linear-gradient-5)"
            strokeWidth={0.43}
            strokeMiterlimit={10}
            fill="none"
          />
          <g className="cloudcomputing_svg__cls-7">
            <path
              d="M108.91 33.6s-69.73.71-69.73 0v118.31a2.61 2.61 0 001.48 2.09l29.84 17c.14.06.24.13.38.2s.1 0 .14.07a1.45 1.45 0 00.24.1 2.9 2.9 0 00.55.17h.13a.92.92 0 00.31.07h.07a.29.29 0 01.14 0h.28a.11.11 0 01.1 0h1.62a1.31 1.31 0 00.34 0h.2c.21 0 .38 0 .59-.07h.18c.07 0 .2 0 .27-.07a.58.58 0 00.21-.07 2.68 2.68 0 00.27-.07h.17l.42-.14s.1 0 .14-.07.34-.17.48-.23l29.63-17h.07a.71.71 0 00.14-.1.33.33 0 00.17-.13s.11-.07.14-.11a.48.48 0 00.17-.13l.17-.17.11-.1a.15.15 0 000-.07.7.7 0 01.14-.17v-.07c0-.07 0 0 0 0s.07-.1.07-.17v-.1-.14-.1a.34.34 0 010-.17V33.6z"
              fill="url(#cloudcomputing_svg__linear-gradient-6)"
            />
          </g>
          <g className="cloudcomputing_svg__cls-7">
            <path
              d="M102.41 52.22s-56.74.59-56.74 0v96.79a2.13 2.13 0 001.24 1.73l24.28 13.91a3.07 3.07 0 00.31.17s.08 0 .11.06l.19.08a2.59 2.59 0 00.45.14h.11a1.11 1.11 0 00.25 0h.06a.2.2 0 01.11 0H73a.15.15 0 01.09 0h1.31a.86.86 0 00.28 0h.14c.17 0 .31 0 .48-.05s.08 0 .14 0a2.18 2.18 0 00.22-.06.37.37 0 00.17 0l.22-.06h.14c.12 0 .23-.09.34-.12s.09 0 .11 0 .28-.14.4-.2l24.11-13.91h.06a.44.44 0 01.11-.08.38.38 0 00.14-.11l.11-.09a.45.45 0 00.14-.11l.14-.14.08-.09v-.05a.36.36 0 01.11-.14v-.06a.34.34 0 000-.14v-.08-.11-.08a.36.36 0 010-.14V52.22z"
              fill="url(#cloudcomputing_svg__linear-gradient-7)"
            />
          </g>
        </g>
      </g>
      <g id="cloudcomputing_svg__lights">
        <g
          id="cloudcomputing_svg__lights-2"
          data-name="lights"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={0.43}
          fill="none"
        >
          <path
            d="M75.55 133.67v51.13"
            stroke="url(#cloudcomputing_svg__linear-gradient-8)"
          />
          <path
            d="M13.58 119.8v26.43"
            stroke="url(#cloudcomputing_svg__linear-gradient-9)"
          />
          <path
            d="M134.48 117.63v27.74"
            stroke="url(#cloudcomputing_svg__linear-gradient-10)"
          />
        </g>
      </g>
      <g id="cloudcomputing_svg__line2">
        <path
          id="cloudcomputing_svg__line2-2"
          data-name="line2"
          d="M131.9 107.09c3.47 2 3.47 5.28.06 7.32l-51.44 30a13.67 13.67 0 01-12.57 0l-51.74-30c-3.47-2-3.53-5.28-.06-7.32l51.44-30a13.67 13.67 0 0112.57 0z"
          stroke="url(#cloudcomputing_svg__linear-gradient-11)"
          strokeWidth={0.43}
          strokeMiterlimit={10}
          fill="none"
        />
      </g>
      <g id="cloudcomputing_svg__line1">
        <path
          id="cloudcomputing_svg__line1-2"
          data-name="line1"
          d="M131.9 97.55c3.47 2 3.47 5.28.06 7.33l-51.44 30a13.67 13.67 0 01-12.57 0l-51.74-30c-3.47-2-3.53-5.29-.06-7.33l51.44-30a13.67 13.67 0 0112.57 0z"
          stroke="url(#cloudcomputing_svg__linear-gradient-12)"
          strokeWidth={0.43}
          strokeMiterlimit={10}
          fill="none"
        />
      </g>
      <g id="cloudcomputing_svg__cloud">
        <g
          opacity={0.8}
          clipPath="url(#cloudcomputing_svg__clip-path)"
          id="cloudcomputing_svg__cloud-2"
          data-name="cloud"
        >
          <path
            d="M27.7 68.49a30 30 0 006.3 4.69l69.55 40.1c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31.05a21 21 0 00-7.46-2.71 1.52 1.52 0 01-1.15-.79c-4.33-7.57-10.39-14.16-17-18-11.17-6.42-20.52-3-23.16 7.29a.67.67 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .21-12 8.07-.05 6.39 3.37 13.8 8.24 18.83z"
            stroke="url(#cloudcomputing_svg__linear-gradient-13)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M28.6 68a30.31 30.31 0 006.27 4.7l69.55 40.14c5.15 3 9.35-.21 9.35-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.67 1.67 0 01-.79-1.16c-1.73-12-10.09-25.23-20.23-31a21 21 0 00-7.45-2.72 1.54 1.54 0 01-1.16-.78c-4.32-7.57-10.38-14.16-17-18-11.17-6.42-20.52-3-23.16 7.29a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .2-12 8.07C20.32 55.6 23.78 63 28.6 68z"
            stroke="url(#cloudcomputing_svg__linear-gradient-14)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M29.55 67.54a29.86 29.86 0 006.26 4.69l69.55 40.1c5.15 3 9.36-.2 9.36-7.08s-4.21-14.9-9.36-17.91L104 86.6a1.63 1.63 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31a20.82 20.82 0 00-7.46-2.72 1.54 1.54 0 01-1.15-.78c-4.33-7.58-10.38-14.17-17-18-11.16-6.42-20.52-3-23.15 7.29a.68.68 0 01-.37.45.75.75 0 01-.62 0c-6.72-3.21-12 .21-12 8.07.03 6.35 3.45 13.76 8.31 18.78z"
            stroke="url(#cloudcomputing_svg__linear-gradient-15)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M30.46 67.05a30 30 0 006.26 4.69l69.55 40.14c5.19 3 9.39-.25 9.39-7.08s-4.2-14.9-9.35-17.91l-1.31-.74a1.65 1.65 0 01-.78-1.15C102.48 73 94.11 59.76 84 54a21 21 0 00-7.46-2.71 1.54 1.54 0 01-1.15-.79c-4.33-7.57-10.39-14.16-17-18-11.17-6.42-20.52-3-23.15 7.29a.69.69 0 01-.38.45.81.81 0 01-.61 0c-6.72-3.21-12 .21-12 8.07-.07 6.34 3.39 13.69 8.21 18.74z"
            stroke="url(#cloudcomputing_svg__linear-gradient-16)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M31.4 66.59a30.31 30.31 0 006.27 4.7l69.55 40.1c5.15 3 9.35-.21 9.35-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.16c-1.73-12-10.1-25.23-20.23-31a21.08 21.08 0 00-7.46-2.72 1.54 1.54 0 01-1.16-.78c-4.28-7.49-10.34-14.12-17-18-11.17-6.42-20.52-3-23.12 7.29a.67.67 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .21-12 8.07-.04 6.35 3.38 13.76 8.24 18.78z"
            stroke="url(#cloudcomputing_svg__linear-gradient-17)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M32.31 66.1a29.86 29.86 0 006.26 4.69l69.55 40.14c5.15 3 9.36-.2 9.36-7.08s-4.21-14.9-9.36-17.91l-1.32-.74a1.63 1.63 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31a20.82 20.82 0 00-7.46-2.72 1.54 1.54 0 01-1.15-.78c-4.33-7.58-10.38-14.16-17-18C49 25.05 39.65 28.43 37 38.76a.72.72 0 01-.37.46.83.83 0 01-.62 0c-6.72-3.22-12 .2-12 8.06-.01 6.39 3.48 13.8 8.3 18.82z"
            stroke="url(#cloudcomputing_svg__linear-gradient-18)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M33.26 65.61a30.57 30.57 0 006.26 4.69l69.55 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31.05a21 21 0 00-7.46-2.71 1.55 1.55 0 01-1.15-.8c-4.33-7.57-10.39-14.16-17-18C50 24.56 40.59 27.93 38 38.27a.72.72 0 01-.38.45.81.81 0 01-.61 0c-6.72-3.21-12 .21-12 8.07-.01 6.42 3.39 13.83 8.25 18.82z"
            stroke="url(#cloudcomputing_svg__linear-gradient-19)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M34.16 65.15a30.31 30.31 0 006.27 4.7L110 110c5.15 3 9.35-.21 9.35-7.08S115.13 88 110 85l-1.32-.75a1.61 1.61 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31a21 21 0 00-7.46-2.72 1.54 1.54 0 01-1.21-.86c-4.32-7.58-10.38-14.16-17-18-11.17-6.43-20.52-3-23.16 7.28a.69.69 0 01-.37.46.83.83 0 01-.62 0c-6.71-3.21-12 .2-12 8.07.03 6.39 3.49 13.8 8.31 18.82z"
            stroke="url(#cloudcomputing_svg__linear-gradient-20)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M35.11 64.66a30.3 30.3 0 006.27 4.69l69.55 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.79-1.15c-1.73-12-10.09-25.24-20.23-31a20.77 20.77 0 00-7.45-2.72 1.54 1.54 0 01-1.14-.82C75.66 40.49 69.6 33.9 63 30c-11.17-6.38-20.52-3-23.12 7.33a.69.69 0 01-.37.46.83.83 0 01-.62 0c-6.71-3.22-12 .2-12 8.06-.06 6.42 3.36 13.83 8.22 18.81z"
            stroke="url(#cloudcomputing_svg__linear-gradient-21)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M36 64.21a30.57 30.57 0 006.26 4.69l69.57 40.1c5.15 3 9.36-.21 9.36-7.08S117 87 111.83 84l-1.32-.74a1.65 1.65 0 01-.78-1.15C108 70.09 99.64 56.88 89.5 51.07a20.78 20.78 0 00-7.5-2.71 1.55 1.55 0 01-1.15-.79c-4.33-7.57-10.38-14.16-17-18-11.16-6.42-20.52-3.05-23.15 7.29a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .21-12 8.07.03 6.39 3.49 13.8 8.29 18.83z"
            stroke="url(#cloudcomputing_svg__linear-gradient-22)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M37 63.71a30 30 0 006.26 4.7l69.55 40.14c5.15 3 9.35-.21 9.35-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.16c-1.73-12-10.1-25.24-20.23-31A21 21 0 0083 47.9a1.53 1.53 0 01-1.15-.78c-4.29-7.53-10.35-14.12-17-18-11.17-6.42-20.52-3.05-23.16 7.29a.67.67 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .2-12 8.07-.01 6.39 3.4 13.76 8.3 18.78z"
            stroke="url(#cloudcomputing_svg__linear-gradient-23)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M37.87 63.26A30 30 0 0044.14 68l69.55 40.1c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.7 1.7 0 01-.79-1.15c-1.73-12-10.09-25.24-20.23-31a20.77 20.77 0 00-7.45-2.72 1.54 1.54 0 01-1.16-.78c-4.32-7.58-10.38-14.17-17-18-11.17-6.42-20.52-3-23.16 7.29a.68.68 0 01-.37.45.75.75 0 01-.62 0c-6.71-3.21-12 .21-12 8.07 0 6.3 3.46 13.71 8.28 18.73z"
            stroke="url(#cloudcomputing_svg__linear-gradient-24)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M38.82 62.76a30.22 30.22 0 006.26 4.7l69.55 40.14c5.15 3 9.36-.21 9.36-7.08s-4.21-14.91-9.36-17.91l-1.31-.74a1.67 1.67 0 01-.79-1.15c-1.73-12-10.09-25.24-20.23-31A20.78 20.78 0 0084.84 47a1.55 1.55 0 01-1.15-.79c-4.33-7.57-10.38-14.16-17-18-11.16-6.38-20.51-3-23.11 7.33a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .21-12 8.07-.05 6.31 3.41 13.68 8.23 18.7z"
            stroke="url(#cloudcomputing_svg__linear-gradient-25)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M39.73 62.31A30.22 30.22 0 0046 67l69.55 40.1c5.15 3 9.35-.21 9.35-7.09s-4.2-14.9-9.35-17.9l-1.32-.75a1.61 1.61 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31a21 21 0 00-7.46-2.72 1.53 1.53 0 01-1.15-.78c-4.33-7.58-10.39-14.16-17-18-11.17-6.43-20.52-3-23.16 7.28a.69.69 0 01-.37.46.81.81 0 01-.61 0c-6.72-3.22-12 .2-12 8.07.02 6.36 3.44 13.77 8.26 18.79z"
            stroke="url(#cloudcomputing_svg__linear-gradient-26)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M40.67 61.82a30.3 30.3 0 006.27 4.69l69.55 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31A20.82 20.82 0 0086.7 46a1.54 1.54 0 01-1.16-.78c-4.32-7.58-10.38-14.17-17-18-11.17-6.42-20.52-3-23.16 7.29a.65.65 0 01-.37.45.75.75 0 01-.62 0c-6.71-3.21-12 .21-12 8.07 0 6.4 3.42 13.77 8.28 18.79z"
            stroke="url(#cloudcomputing_svg__linear-gradient-27)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M41.58 61.37a30.85 30.85 0 006.26 4.69l69.55 40.1c5.2 3 9.4-.21 9.4-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.7 1.7 0 01-.79-1.16c-1.73-12-10.09-25.23-20.23-31a21 21 0 00-7.45-2.72 1.54 1.54 0 01-1.16-.78c-4.32-7.57-10.38-14.16-17-18C58.31 20.28 49 23.65 46.32 34a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .21-12 8.07.01 6.41 3.43 13.82 8.25 18.85z"
            stroke="url(#cloudcomputing_svg__linear-gradient-28)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M42.53 60.87a29.86 29.86 0 006.26 4.69l69.55 40.15c5.15 3 9.35-.25 9.35-7.09s-4.2-14.9-9.35-17.9L117 80a1.61 1.61 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31a21 21 0 00-7.46-2.72 1.54 1.54 0 01-1.15-.78c-4.29-7.54-10.34-14.16-17-18-11.16-6.43-20.52-3.09-23.11 7.28a.7.7 0 01-.37.46.83.83 0 01-.62 0c-6.72-3.21-12 .2-12 8.07-.03 6.28 3.39 13.69 8.25 18.71z"
            stroke="url(#cloudcomputing_svg__linear-gradient-29)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <path
            d="M43.44 60.38a30 30 0 006.26 4.69l69.59 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.29-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31a21 21 0 00-7.46-2.71 1.53 1.53 0 01-1.15-.78c-4.33-7.58-10.39-14.17-17-18-11.17-6.42-20.52-3-23.16 7.29a.65.65 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .21-12 8.07-.03 6.35 3.38 13.75 8.21 18.73z"
            stroke="url(#cloudcomputing_svg__linear-gradient-30)"
            strokeWidth={0.44}
            strokeMiterlimit={10}
            fill="none"
          />
          <g opacity={0.3} strokeWidth={0.44} strokeMiterlimit={10} fill="none">
            <path
              d="M27.7 68.49a30 30 0 006.3 4.69l69.55 40.1c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31.05a21 21 0 00-7.46-2.71 1.52 1.52 0 01-1.15-.79c-4.33-7.57-10.39-14.16-17-18-11.17-6.42-20.52-3-23.16 7.29a.67.67 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .21-12 8.07-.05 6.39 3.37 13.8 8.24 18.83z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-31)"
              opacity={0.3}
            />
            <path
              d="M28.6 68a30.31 30.31 0 006.27 4.7l69.55 40.14c5.15 3 9.35-.21 9.35-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.67 1.67 0 01-.79-1.16c-1.73-12-10.09-25.23-20.23-31a21 21 0 00-7.45-2.72 1.54 1.54 0 01-1.16-.78c-4.32-7.57-10.38-14.16-17-18-11.17-6.42-20.52-3-23.16 7.29a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .2-12 8.07C20.32 55.6 23.78 63 28.6 68z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-32)"
              opacity={0.3}
            />
            <path
              d="M29.55 67.54a29.86 29.86 0 006.26 4.69l69.55 40.1c5.15 3 9.36-.2 9.36-7.08s-4.21-14.9-9.36-17.91L104 86.6a1.63 1.63 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31a20.82 20.82 0 00-7.46-2.72 1.54 1.54 0 01-1.15-.78c-4.33-7.58-10.38-14.17-17-18-11.16-6.42-20.52-3-23.15 7.29a.68.68 0 01-.37.45.75.75 0 01-.62 0c-6.72-3.21-12 .21-12 8.07.03 6.35 3.45 13.76 8.31 18.78z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-33)"
              opacity={0.3}
            />
            <path
              d="M30.46 67.05a30 30 0 006.26 4.69l69.55 40.14c5.19 3 9.39-.25 9.39-7.08s-4.2-14.9-9.35-17.91l-1.31-.74a1.65 1.65 0 01-.78-1.15C102.48 73 94.11 59.76 84 54a21 21 0 00-7.46-2.71 1.54 1.54 0 01-1.15-.79c-4.33-7.57-10.39-14.16-17-18-11.17-6.42-20.52-3-23.15 7.29a.69.69 0 01-.38.45.81.81 0 01-.61 0c-6.72-3.21-12 .21-12 8.07-.07 6.34 3.39 13.69 8.21 18.74z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-34)"
              opacity={0.3}
            />
            <path
              d="M31.4 66.59a30.31 30.31 0 006.27 4.7l69.55 40.1c5.15 3 9.35-.21 9.35-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.16c-1.73-12-10.1-25.23-20.23-31a21.08 21.08 0 00-7.46-2.72 1.54 1.54 0 01-1.16-.78c-4.28-7.49-10.34-14.12-17-18-11.17-6.42-20.52-3-23.12 7.29a.67.67 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .21-12 8.07-.04 6.35 3.38 13.76 8.24 18.78z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-35)"
              opacity={0.3}
            />
            <path
              d="M32.31 66.1a29.86 29.86 0 006.26 4.69l69.55 40.14c5.15 3 9.36-.2 9.36-7.08s-4.21-14.9-9.36-17.91l-1.32-.74a1.63 1.63 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31a20.82 20.82 0 00-7.46-2.72 1.54 1.54 0 01-1.15-.78c-4.33-7.58-10.38-14.16-17-18C49 25.05 39.65 28.43 37 38.76a.72.72 0 01-.37.46.83.83 0 01-.62 0c-6.72-3.22-12 .2-12 8.06-.01 6.39 3.48 13.8 8.3 18.82z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-36)"
              opacity={0.3}
            />
            <path
              d="M33.26 65.61a30.57 30.57 0 006.26 4.69l69.55 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31.05a21 21 0 00-7.46-2.71 1.55 1.55 0 01-1.15-.8c-4.33-7.57-10.39-14.16-17-18C50 24.56 40.59 27.93 38 38.27a.72.72 0 01-.38.45.81.81 0 01-.61 0c-6.72-3.21-12 .21-12 8.07-.01 6.42 3.39 13.83 8.25 18.82z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-37)"
              opacity={0.3}
            />
            <path
              d="M34.16 65.15a30.31 30.31 0 006.27 4.7L110 110c5.15 3 9.35-.21 9.35-7.08S115.13 88 110 85l-1.32-.75a1.61 1.61 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31a21 21 0 00-7.46-2.72 1.54 1.54 0 01-1.21-.86c-4.32-7.58-10.38-14.16-17-18-11.17-6.43-20.52-3-23.16 7.28a.69.69 0 01-.37.46.83.83 0 01-.62 0c-6.71-3.21-12 .2-12 8.07.03 6.39 3.49 13.8 8.31 18.82z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-38)"
              opacity={0.3}
            />
            <path
              d="M35.11 64.66a30.3 30.3 0 006.27 4.69l69.55 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.79-1.15c-1.73-12-10.09-25.24-20.23-31a20.77 20.77 0 00-7.45-2.72 1.54 1.54 0 01-1.14-.82C75.66 40.49 69.6 33.9 63 30c-11.17-6.38-20.52-3-23.12 7.33a.69.69 0 01-.37.46.83.83 0 01-.62 0c-6.71-3.22-12 .2-12 8.06-.06 6.42 3.36 13.83 8.22 18.81z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-39)"
              opacity={0.3}
            />
            <path
              d="M36 64.21a30.57 30.57 0 006.26 4.69l69.57 40.1c5.15 3 9.36-.21 9.36-7.08S117 87 111.83 84l-1.32-.74a1.65 1.65 0 01-.78-1.15C108 70.09 99.64 56.88 89.5 51.07a20.78 20.78 0 00-7.5-2.71 1.55 1.55 0 01-1.15-.79c-4.33-7.57-10.38-14.16-17-18-11.16-6.42-20.52-3.05-23.15 7.29a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .21-12 8.07.03 6.39 3.49 13.8 8.29 18.83z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-40)"
              opacity={0.3}
            />
            <path
              d="M37 63.71a30 30 0 006.26 4.7l69.55 40.14c5.15 3 9.35-.21 9.35-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.16c-1.73-12-10.1-25.24-20.23-31A21 21 0 0083 47.9a1.53 1.53 0 01-1.15-.78c-4.29-7.53-10.35-14.12-17-18-11.17-6.42-20.52-3.05-23.16 7.29a.67.67 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .2-12 8.07-.01 6.39 3.4 13.76 8.3 18.78z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-41)"
              opacity={0.3}
            />
            <path
              d="M37.87 63.26A30 30 0 0044.14 68l69.55 40.1c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.7 1.7 0 01-.79-1.15c-1.73-12-10.09-25.24-20.23-31a20.77 20.77 0 00-7.45-2.72 1.54 1.54 0 01-1.16-.78c-4.32-7.58-10.38-14.17-17-18-11.17-6.42-20.52-3-23.16 7.29a.68.68 0 01-.37.45.75.75 0 01-.62 0c-6.71-3.21-12 .21-12 8.07 0 6.3 3.46 13.71 8.28 18.73z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-42)"
              opacity={0.3}
            />
            <path
              d="M38.82 62.76a30.22 30.22 0 006.26 4.7l69.55 40.14c5.15 3 9.36-.21 9.36-7.08s-4.21-14.91-9.36-17.91l-1.31-.74a1.67 1.67 0 01-.79-1.15c-1.73-12-10.09-25.24-20.23-31A20.78 20.78 0 0084.84 47a1.55 1.55 0 01-1.15-.79c-4.33-7.57-10.38-14.16-17-18-11.16-6.38-20.51-3-23.11 7.33a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .21-12 8.07-.05 6.31 3.41 13.68 8.23 18.7z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-43)"
              opacity={0.3}
            />
            <path
              d="M39.73 62.31A30.22 30.22 0 0046 67l69.55 40.1c5.15 3 9.35-.21 9.35-7.09s-4.2-14.9-9.35-17.9l-1.32-.75a1.61 1.61 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31a21 21 0 00-7.46-2.72 1.53 1.53 0 01-1.15-.78c-4.33-7.58-10.39-14.16-17-18-11.17-6.43-20.52-3-23.16 7.28a.69.69 0 01-.37.46.81.81 0 01-.61 0c-6.72-3.22-12 .2-12 8.07.02 6.36 3.44 13.77 8.26 18.79z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-44)"
              opacity={0.3}
            />
            <path
              d="M40.67 61.82a30.3 30.3 0 006.27 4.69l69.55 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.32-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31A20.82 20.82 0 0086.7 46a1.54 1.54 0 01-1.16-.78c-4.32-7.58-10.38-14.17-17-18-11.17-6.42-20.52-3-23.16 7.29a.65.65 0 01-.37.45.75.75 0 01-.62 0c-6.71-3.21-12 .21-12 8.07 0 6.4 3.42 13.77 8.28 18.79z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-45)"
              opacity={0.3}
            />
            <path
              d="M41.58 61.37a30.85 30.85 0 006.26 4.69l69.55 40.1c5.2 3 9.4-.21 9.4-7.08s-4.2-14.91-9.35-17.91l-1.32-.74a1.7 1.7 0 01-.79-1.16c-1.73-12-10.09-25.23-20.23-31a21 21 0 00-7.45-2.72 1.54 1.54 0 01-1.16-.78c-4.32-7.57-10.38-14.16-17-18C58.31 20.28 49 23.65 46.32 34a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .21-12 8.07.01 6.41 3.43 13.82 8.25 18.85z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-46)"
              opacity={0.3}
            />
            <path
              d="M42.53 60.87a29.86 29.86 0 006.26 4.69l69.55 40.15c5.15 3 9.35-.25 9.35-7.09s-4.2-14.9-9.35-17.9L117 80a1.61 1.61 0 01-.78-1.15c-1.73-12-10.09-25.24-20.23-31a21 21 0 00-7.46-2.72 1.54 1.54 0 01-1.15-.78c-4.29-7.54-10.34-14.16-17-18-11.16-6.43-20.52-3.09-23.11 7.28a.7.7 0 01-.37.46.83.83 0 01-.62 0c-6.72-3.21-12 .2-12 8.07-.03 6.28 3.39 13.69 8.25 18.71z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-47)"
              opacity={0.3}
            />
            <path
              d="M43.44 60.38a30 30 0 006.26 4.69l69.59 40.14c5.15 3 9.35-.2 9.35-7.08s-4.2-14.9-9.35-17.91l-1.29-.74a1.65 1.65 0 01-.78-1.15c-1.73-12-10.1-25.24-20.23-31a21 21 0 00-7.46-2.71 1.53 1.53 0 01-1.15-.78c-4.33-7.58-10.39-14.17-17-18-11.17-6.42-20.52-3-23.16 7.29a.65.65 0 01-.37.45.83.83 0 01-.62 0c-6.71-3.21-12 .21-12 8.07-.03 6.35 3.38 13.75 8.21 18.73z"
              style={{
                isolation: "isolate",
              }}
              stroke="url(#cloudcomputing_svg__linear-gradient-48)"
              opacity={0.3}
            />
          </g>
          <path
            d="M27.45 68.36a30.22 30.22 0 006.26 4.7l69.59 40.14c5.15 3 9.36-.21 9.36-7.08s-4.21-14.91-9.36-17.91l-1.3-.74a1.65 1.65 0 01-.78-1.16c-1.73-12-10.09-25.23-20.23-31a21 21 0 00-7.46-2.72 1.52 1.52 0 01-1.15-.78C68 44.2 62 37.61 55.34 33.74 44.18 27.32 34.82 30.69 32.19 41a.7.7 0 01-.37.45.83.83 0 01-.62 0c-6.72-3.21-12 .21-12 8.07.01 6.48 3.43 13.86 8.25 18.84z"
            style={{
              isolation: "isolate",
            }}
            opacity={0.81}
            fill="url(#cloudcomputing_svg__linear-gradient-49)"
          />
          <path
            d="M44.63 32.17c-5.36 0-9.31 3.38-10.79 9.31A2.32 2.32 0 0132.56 43a2.14 2.14 0 01-1 .21 2.18 2.18 0 01-1-.25 9.29 9.29 0 00-4-1c-3.5 0-5.56 2.84-5.56 7.62 0 5.68 3.13 12.76 7.79 17.62a28.14 28.14 0 005.81 4.33l69.55 40.14a6 6 0 002.84.9c2.43 0 4-2.55 4-6.5 0-6.26-3.92-13.79-8.53-16.43l-1.34-.64a3.15 3.15 0 01-1.57-2.35 43.43 43.43 0 00-6.71-17.16A39.21 39.21 0 0080.15 56.8a19.4 19.4 0 00-6.84-2.52 3.25 3.25 0 01-2.39-1.6C66.55 45 60.7 38.84 54.48 35.26a20 20 0 00-9.85-3.09zm0-1.64a21.79 21.79 0 0110.71 3.29c6.68 3.83 12.69 10.46 17 18a1.67 1.67 0 001.15.79A20.23 20.23 0 0181 55.35c10.14 5.77 18.54 19 20.27 31a1.67 1.67 0 00.79 1.15l1.31.74c5.15 3 9.36 11 9.36 17.91 0 5.1-2.35 8.19-5.69 8.19a7.66 7.66 0 01-3.71-1.11L33.75 73.1a29.5 29.5 0 01-6.26-4.69c-4.86-5-8.28-12.44-8.28-18.78 0-5.89 2.92-9.3 7.25-9.3a11.22 11.22 0 014.78 1.19.79.79 0 00.29.08.84.84 0 00.29-.08.6.6 0 00.37-.45c1.73-6.75 6.34-10.54 12.44-10.54z"
            fill="url(#cloudcomputing_svg__linear-gradient-50)"
          />
          <path
            d="M60.7 22.71c-6 0-10.38 3.74-12 10.25a1.11 1.11 0 01-.62.74 1 1 0 01-.45.12 1.15 1.15 0 01-.5-.12 10.58 10.58 0 00-4.61-1.15c-4.2 0-6.84 3.41-6.84 8.89 0 6.09 3.21 13.34 8.16 18.48A31 31 0 0050 64.54l69.55 40.1a7.13 7.13 0 003.5 1.07c3.21 0 5.27-3 5.27-7.78 0-6.76-4.12-14.62-9.19-17.54l-1.31-.74a1.88 1.88 0 01-1-1.45c-1.73-12.14-10.14-25.07-20-30.75a19.83 19.83 0 00-7.33-2.64 1.92 1.92 0 01-1.44-1C83.57 36 77.55 29.62 71.16 26a20.67 20.67 0 00-10.46-3.29zm0-.42a21.71 21.71 0 0110.71 3.3c6.68 3.83 12.69 10.45 17 18a1.65 1.65 0 001.15.78A20.26 20.26 0 0197 47.12c10.09 5.85 18.5 19 20.23 31a1.65 1.65 0 00.78 1.16l1.32.74c5.15 2.92 9.36 11 9.36 17.82 0 5.11-2.35 8.2-5.69 8.2a7.66 7.66 0 01-3.71-1.11L49.78 64.82a28.6 28.6 0 01-6.26-4.69c-4.86-5-8.28-12.43-8.28-18.77 0-5.89 2.92-9.31 7.25-9.31a11.1 11.1 0 014.78 1.2.55.55 0 00.57 0 .63.63 0 00.38-.46c1.78-6.71 6.38-10.5 12.48-10.5z"
            style={{
              isolation: "isolate",
            }}
            fill="url(#cloudcomputing_svg__linear-gradient-51)"
            opacity={0.39}
          />
        </g>
      </g>
    </svg>
  );
}

export default SvgCloudcomputing;
