import React, { useEffect, useRef } from "react"
import { Link, Logo } from "src/components/atoms"
import "./styles.scss"

function getScroll() {
  var scrOfY = 0

  if (typeof window.pageYOffset === 'number') {
    //Netscape compliant
    scrOfY = window.pageYOffset
  } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
    //DOM compliant
    scrOfY = document.body.scrollTop
  } else if (document.documentElement && (document.documentElement.scrollLeft || document.documentElement.scrollTop)) {
    //IE6 standards compliant mode
    scrOfY = document.documentElement.scrollTop
  }

  return scrOfY;
}


const MobileNavigation = () => {

  const nav = useRef(null)
  const logo = useRef(null)
  const overlay = useRef(null)
  const circle = useRef(null)
  const menu = useRef(null)
  const hamburger = useRef(null)

  const onScroll = () => {
    const s = (getScroll() > 10)
    nav.current.classList.toggle("scrolled", s)
    logo.current.querySelector(".logo-svg").classList.toggle("inverted", s)
  }
  
  useEffect(() => {
    onScroll()

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', onScroll)
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', onScroll)
      }
    }
  })

  useEffect(() => {
    hide()
  })

  const hasClass = (el, className) => {
    return (" " + el.className + " ").replace(/[\n\t]/g, " ").indexOf(` ${className} `) > -1
  }

  const show = () => {
    
    overlay.current.classList.add("visible")
    setTimeout(() => {
      circle.current.classList.add("expand")
      setTimeout(() => {
        menu.current.querySelectorAll("li").forEach(li => {
          li.classList.add("animate")
        })
        
      }, 300)
    }, 100)
    
    logo.current.querySelector(".logo-svg").classList.toggle("inverted", true)
    hamburger.current.classList.toggle("is-active", true)
  }

  const hide = () => {
    menu.current.querySelectorAll("li").forEach(li => {
      li.classList.remove("animate")
    })
    overlay.current.classList.remove("visible")
    circle.current.classList.remove("expand")

    if (!hasClass(nav.current, "scrolled")) 
      logo.current.querySelector(".logo-svg").classList.toggle("inverted", false)
    
    hamburger.current.classList.toggle("is-active", false)
  }

  return <>
    <nav className="mobile-navigation" ref={nav}>
      <div className="logo" ref={logo}>
        <Link to="/">
          <Logo />
        </Link>
      </div>

      <button className="hamburger hamburger--spin" ref={hamburger} onClick={(evt) => {
        if (hasClass(overlay.current, "visible")) hide() 
        else show()
      }}>
        <span className="hamburger-box">
          <span className="hamburger-inner"></span>
        </span>
      </button>
    </nav>
    <div className="mobile-nav-overlay" ref={overlay}>
      <div className="circle" ref={circle}></div>
      <div className="menu" ref={menu}>
        <ul>
          <li><Link to="#!/solutions">Solutions</Link></li>
          <li><Link to="#!/services">Services</Link></li>
          <li><Link to="/about">About</Link></li>
          <li><Link to="mailto:info@rdwadvisorsllc.com">Contact Us</Link></li>
        </ul>
        
      </div>
    </div>
  </>
}

export default MobileNavigation