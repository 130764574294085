import React from "react"
import { ReactComponent as LogoSvg } from "src/assets/svg/logo.svg" 
import "./styles.scss" 

const Logo = () => {
  return <div className="logo-svg">
    <LogoSvg />
  </div>
}

export default Logo 