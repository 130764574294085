import React, { useRef, useState, useEffect } from "react"
import gsap, {ScrollToPlugin, ScrollTrigger, Power4 } from "gsap/all"
import "./styles.scss"

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

const Layout = ({children}) => {
  const scrollTween = useRef(null)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    setTimeout(() => setIsReady(true), 100)
  })

  useEffect(() => {
    const hash = window.location.hash
    if (hash !== undefined && hash !== null && hash !== "") {
      // setTimeout(() => {
      //   window.scrollTo(0,0)
      // }, 1)
      setTimeout(() => {
        let target = hash.replace("#!/", "")
        const el = document.getElementById(target)

        if (el !== null) {
          // const nav = document.getElementsByClassName("primary-navigation")[0]
          // let offset = el.offsetTop - nav.offsetHeight
          scrollTween.current = gsap.to(window, { duration: 1.5, scrollTo: (el.offsetTop - 48), ease: Power4.easeInOut })
        }
      }, 250)
    }

    return () => {
      if (scrollTween.current) scrollTween.current.kill()
    }
  })

  return (
    <main className={isReady ? `main ready` : `main`}>
      {children}
    </main>
  )
}

export default Layout