import React from "react"
import "./styles.scss"

const FooterColumn = ({ children, title, width }) => {
  return (
    <div className="footer-column" style={{flex: `0 1 ${width}px`}}>
      <h6>{title}</h6>
      {children}
    </div>
  )
}

export default FooterColumn