import React from "react" 
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Link, UnderlineLink, Button } from "../../atoms"
import "./styles.scss"


const Navigation = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 254) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)


  return (
    <nav className="primary-navigation">
      <div className="container">
        <div className="logo">
          <Link to="/">
            <Img fluid={data.logo.childImageSharp.fluid} alt="RDW Technologies" />
          </Link>
        </div>
        
        <div className="links">
          <Button to={"mailto:info@rdwadvisorsllc.com"} style={{margin: 0, marginLeft: "3.75rem", minWidth: "8.25rem"}}>Contact</Button>
          <UnderlineLink to="/about">About</UnderlineLink>
          <UnderlineLink to="/#!/services">Services</UnderlineLink>
          <UnderlineLink to="/#!/solutions">Solutions</UnderlineLink>
        </div>
      </div>
    </nav>
  )
}

export default Navigation