import React from "react"
import "./styles.scss"

import { Link, Logo, UnderlineLink } from "src/components/atoms"
import { FooterColumn, SocialLinks } from "src/components/molecules"

const Footer = () => {


  return (
    <footer className="site-footer">
      <div className="footer-content">
        <div className="logo">
          <Link to="/">
            <Logo />
          </Link>
        </div>
        <div className="menu">
          <FooterColumn title="Services" width={206}>
            <div><UnderlineLink to="/#!/services">Artificial Intelligience</UnderlineLink></div>
            <div><UnderlineLink to="/#!/services">Advisory & Consulting</UnderlineLink></div>
            <div><UnderlineLink to="/#!/services">Big Data & Analytics</UnderlineLink></div>
            <div><UnderlineLink to="/#!/services">Cloud computing</UnderlineLink></div>
            <div><UnderlineLink to="/#!/services">Cybersecurity</UnderlineLink></div>
            <div><UnderlineLink to="/#!/services">Enterprise Collaboration</UnderlineLink></div>
          </FooterColumn>
          <FooterColumn title="Solutions" width={86}>
            <div><UnderlineLink to="/#!/solutions">ICS</UnderlineLink></div>
          </FooterColumn>
          <FooterColumn title="About" width={126}>
            <div><UnderlineLink to="/about/#!/philosophy">Our Philosophy</UnderlineLink></div>
          </FooterColumn>
        </div>
      </div>
      <div className="copyright">
        <hr />
        <div className="text-content">
          <p>&copy; 2021 RDW Technologies. All right reserved.</p>
        </div>
      </div>
    </footer>
  )
}

export default Footer